import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiAvAsset, ApiLiveSchedule, ApiLiveScheduleList, ApiLiveStream } from '@tytapp/api';
import { deepCopy, LoggerService, PeriodicTaskService } from '@tytapp/common';
import { isClientSide } from '@tytapp/environment-utils';
import { UserService } from '@tytapp/user';
import { Subject } from 'rxjs';

export function youtubeAvAsset(id: string): ApiAvAsset {
    return {
        provider: 'youtube',
        url: `https://www.youtube.com/watch?v=${id}`,
        identifier: id
    };
}

export function liveStream(options: Partial<ApiLiveStream> & { platform_name: string, asset: ApiAvAsset }) {
    return {
        active: true,
        available: true,
        created_at: '2024-01-01T00:00:00Z',
        started: '2024-01-01T00:00:00Z',
        title: 'Simulated Live Stream',
        type: 'live_stream',
        live_events: [],
        ...options
    };
}

export function youtubeLiveStream(youtubeId: string, title: string, options: Partial<ApiLiveStream> = {}): ApiLiveStream {
    return liveStream({
        title,
        platform_name: 'youtube',
        asset: youtubeAvAsset(youtubeId),
        ...options
    });
}

export function youtubePersistentLiveStream(youtubeId: string, title: string, options: Partial<ApiLiveStream> = {}): ApiLiveStream {
    return youtubeLiveStream(youtubeId, title, {
        persistent: true,
        ...options
    });
}

export interface LiveStreamSimulationSpec {
    id: string;
    label: string;
    summary: string;
    description: string;

    /**
     * Whenever a live stream is marked ended, send a simulated end-of-media event.
     * This can also be accomplished on a per-stream basis, without requiring the stream to be marked ended
     * by using Fired Events.
     */
    simulateEndOfMedia?: boolean,
    schedule?: ApiLiveScheduleList[];
    events: LiveStreamSimulationEvent[];
    messages: LiveStreamSimulationMessage[];
}

export interface LiveStreamSimulationMessage {
    id: number;
    time: number;
    message: string;
}

export interface ActiveLiveStreamSimulationSpec extends LiveStreamSimulationSpec {
    startedAt: number;
}

export interface LiveStreamSimulationEvent {
    id: number;
    time: number;
    label: string;
    type: string;
    change: Partial<ApiLiveStream>;

    /**
     * Moment in time events that should occur when this event is first processed.
     * These are things like end of stream events or in-stream messages.
     */
    firedEvents?: LiveStreamSimulationFiredEvent[];
}

/**
 * Fired events are events that happen in the context of a given live stream but do not affect its overall state.
 * For example this can be an end-of-stream event, or an in-stream message, or a poll appearing, etc.
 *
 * Right now it only supports end of stream events.
 */
export interface LiveStreamSimulationFiredEvent {
    type: 'end-of-media';
}

export class LiveStreamSimulationBuilder {
    spec: Partial<LiveStreamSimulationSpec> = {
        events: [],
        messages: []
    };

    static named(id: string, label: string) {
        return new LiveStreamSimulationBuilder().withName(id, label);
    }

    withSchedule(schedule: ApiLiveScheduleList[]) {
        this.spec.schedule = schedule;
        return this;
    }

    withName(id: string, label: string) {
        this.spec.id = id;
        this.spec.label = label;
        return this;
    }

    withSummary(summary: string) {
        this.spec.summary = summary;
        return this;
    }

    withDescription(description: string) {
        this.spec.description = description;
        return this;
    }

    nextStreamId = 1000001;
    nextEventId = 1;

    currentTime = 0;

    wait(time: number) {
        this.currentTime += time;
        return this;
    }

    nicknames = new Map<string, number>();

    addEvent(label: string, type: string, change: ApiLiveStream, firedEvents?: LiveStreamSimulationFiredEvent[]) {
        this.spec.events.push({
            id: this.nextEventId++,
            time: this.currentTime,
            label,
            type,
            change,
            firedEvents
        });
        return this;
    }

    simulateEndOfMedia() {
        this.spec.simulateEndOfMedia = true;
        return this;
    }

    /**
     * Send an end-of-media event for the specific live stream, without affecting the state of the ApiLiveStream
     * object.
     * @param nickname
     * @returns
     */
    sendEndOfMediaEvent(nickname: string) {
        this.sendFiredEvent(nickname, `sendEndOfMedia`, `Send end-of-media event`, {
            type: 'end-of-media'
        })
        return this;
    }

    sendFiredEvent(nickname: string, type: string, label: string, firedEvent: LiveStreamSimulationFiredEvent) {
        this.addEvent(
            label,
            type,
            {
                id: this.nicknames.get(nickname)
            },
            [ firedEvent ]
        );
        return this;
    }

    publishStream(nickname: string, stream: Partial<ApiLiveStream>) {
        stream.id ??= this.nextStreamId++;

        if (this.nicknames.has(nickname))
            throw new Error(`Live stream simulation: Nickname ${nickname} is already in use!`);
        this.nicknames.set(nickname, stream.id);
        this.addEvent(
            `Publish stream ${nickname}`,
            'publishStream',
            {
                available: true,
                ...stream
            }
        );

        if (this.currentTime > 0)
            this.message(`New live stream ${nickname} published`);

        return this;
    }

    message(message: string) {
        this.spec.messages.push({
            id: this.nextEventId++,
            time: this.currentTime,
            message
        });

        return this;
    }

    modifyStream(nickname: string, stream: Partial<ApiLiveStream>) {
        this.addEvent(
            `Modify stream ${nickname}`,
            `modifyStream`,
            {
                id: this.nicknames.get(nickname),
                ...stream
            }
        );

        return this;
    }

    startStream(nickname: string) {
        this.addEvent(
            `Start stream ${nickname}`,
            `startStream`,
            {
                id: this.nicknames.get(nickname),
                active: false
            }
        );

        this.message(`Live stream ${nickname} started`);

        return this;
    }

    endStream(nickname: string) {
        this.addEvent(
            `End stream ${nickname}`,
            `endStream`,
            {
                id: this.nicknames.get(nickname),
                active: false,
                is_ended: true
            }
        );

        this.message(`Live stream ${nickname} ended`);

        return this;
    }

    changeLiveEvent(nickname: string, event: ApiLiveSchedule) {
        this.addEvent(
            `Change Live Event for stream ${nickname}`,
            `changeLiveEvent`,
            {
                id: this.nicknames.get(nickname),
                active_live_event: event
            }
        );

        this.message(`Live stream ${nickname}: Current event changed to ${event.title ?? event.show?.name ?? `Event #${event.id ?? '--'}`}`);

        return this;
    }

    build(): LiveStreamSimulationSpec {
        return <LiveStreamSimulationSpec>this.spec;
    }
}

export class LiveStreamSimulation {
    private periodicTasks = inject(PeriodicTaskService);
    private matSnackBar = inject(MatSnackBar);
    private logger = inject(LoggerService).configure({ source: 'livesim' });
    private userService = inject(UserService);

    constructor(readonly spec: ActiveLiveStreamSimulationSpec) {
    }

    start() {
        this.logger.warning(`Starting live stream simulation [${this.spec.id}] ${this.spec.label}`);
        this.pollForChanges();
    }

    private eventOccurred$ = new Subject<LiveStreamSimulationEvent>();
    readonly eventOccurred = this.eventOccurred$.asObservable();

    private pollForChanges() {
        let loadedAt = this.currentTime;
        let messages = this.spec.messages.filter(x => x.time >= loadedAt);
        let applicableEvents = this.spec.events.filter(x => x.time >= loadedAt);
        let passedEvents = this.spec.events.filter(x => x.time < loadedAt);
        let lastEventId = 0;

        if (passedEvents.length > 0) {
            this.logger.info(`${passedEvents.length} events occurred before the application loaded`, passedEvents);
        }

        this.logger.info(`${applicableEvents.length} upcoming events`, applicableEvents);
        this.logger.info(`Initial state`, {
            spec: this.spec,
            streams: this.streams
        });

        this.matSnackBar.open(
            `[LiveStreamSim] Simulation active `
            + `(${this.currentTime / 1000 | 0} seconds in, `
            + `${applicableEvents.length} events remain)`,
            undefined,
            { duration: 3000 }
        );

        this.periodicTasks.schedule(10_000, () => {
            this.logger.info(`Current State`, { streams: this.streams });
        });

        if (messages.length > 0) {
            this.logger.warning(`Scheduling snackbar message periodic task...`);
            this.periodicTasks.schedule(1_000, () => {

                // Show snackbar messages that are part of the simulation

                if (messages.length > 0 && messages[0].time <= this.currentTime) {
                    let message = messages.shift();
                    this.matSnackBar.open(`[LiveStreamSim] ${message.message}`, undefined, {
                        duration: 3000
                    });
                }

                while (applicableEvents.length > 0 && applicableEvents[0].time < this.currentTime) {
                    let event = applicableEvents.shift();
                    this.logger.info(`Event ${event.id} has occurred`, event);
                    this.eventOccurred$.next(event);
                }
            });
        } else {
            this.logger.warning(`No snackbar messages remain in simulation, skipping periodic task.`);
        }
    }

    static named(id: string, label: string) {
        return LiveStreamSimulationBuilder.named(id, label);
    }

    get startedAt() {
        return this.spec.startedAt;
    }

    get liveIndicator() {
        return this.streams.some(x => x.active);
    }

    /**
     * Return true if end-of-media should be simulated for the given simulated live stream.
     * @param id
     * @returns
     */
    isMediaEnded(id: number) {
        if (!this.spec.simulateEndOfMedia)
            return false;

        let stream = this.streams.find(x => x.id === id);
        if (!stream || stream?.is_ended)
            return true;

        if (this.pastFiredEventsForStream(id).some(x => x.type === 'end-of-media'))
            return true;

        return false;
    }

    private overriddenTime: number = undefined;

    get currentTime() {
        return this.overriddenTime ?? (Date.now() - this.startedAt);
    }

    set currentTime(value) {
        this.overriddenTime = value;
    }

    static applyEvents(events: LiveStreamSimulationEvent[]) {
        let streams: ApiLiveStream[] = [];
        for (let event of events) {
            let stream = streams.find(x => x.id === event.change.id);
            if (stream) {
                Object.assign(stream, event.change);
            } else {
                streams.push({ ...event.change });
            }
        }

        return streams;
    }

    /**
     * Retrieve all events which have already occurred.
     */
    get pastEvents() {
        return this.spec.events.filter(x => x.time <= this.currentTime);
    }

    /**
     * Retrieve the set of fired events for a stream which have already happened.
     */
    pastFiredEventsForStream(id: number): LiveStreamSimulationFiredEvent[] {
        return this.pastEvents.filter(x => x.change?.id === id).map(x => x.firedEvents ?? []).flat();
    }

    get streams(): ApiLiveStream[] {
        let currentStreams = this.onlyAvailable(LiveStreamSimulation.applyEvents(this.pastEvents));

        if (!this.userService.entitled) {
            for (let stream of currentStreams) {
                if (stream.premium)
                    stream.asset = undefined;
            }
        }

        return currentStreams;
    }

    private onlyAvailable(streams: ApiLiveStream[]) {
        // Mirrors backend logic
        return streams.filter(x =>
            x.available && (
                (!x.wait_for_live && !x.is_ended)
                || (x.wait_for_live && x.active)
            )
        );
    }

    static active() {
        if (!isClientSide())
            return undefined;

        let storedSpecStr = localStorage['tyt:live-stream-simulation'];
        if (!storedSpecStr) {
            return undefined;
        }

        try {
            let sim = new LiveStreamSimulation(JSON.parse(storedSpecStr));
            sim.start();
            return sim;
        } catch (e) {
            inject(LoggerService).configure({ source: 'livesim' }).error(`Failed to parse active live stream simulation`, e);
            return undefined;
        }
    }

    static end() {
        if (!isClientSide())
            return;

        delete localStorage['tyt:live-stream-simulation'];

        location.reload();
    }

    static apply(spec: LiveStreamSimulationSpec) {
        if (!isClientSide())
            return;

        localStorage['tyt:live-stream-simulation'] = JSON.stringify(<ActiveLiveStreamSimulationSpec>{
            ...spec,
            startedAt: Date.now()
        });

        // Make sure to clear stream end marks
        delete localStorage['tyt.liveStreams.endMarks'];

        // Navigate into the live shortcut so that we immediately jump into the simulated streams.

        location.assign(`/live`);
    }
}