import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiLiveStream, ApiPodcast, ApiProduction, ApiVOD } from '@tytapp/api';
import { DevToolsService } from '@tytapp/common';
import { HostApi } from '@tytapp/common';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PlaybackSession, PlaybackState } from '../media-services';
import { Player } from './player';
import { PlaylistType } from './playlist-type';
import { isClientSide } from '@tytapp/environment-utils';

interface MediaSessionSeekToMessage {
    type: 'media_session_seek_to';
    position: number;
}

interface PlaybackSessionMetadata extends MediaMetadataInit {
    mediumType: 'recorded' | 'live';
}

/**
 * Responsible for coordinating playback between multiple players in the app, as well as between the native shell
 * and the web core when running in TYT Native.
 */
@Injectable()
export class Playback {
    //#region Dependencies

    private hostApi = inject(HostApi);
    private devTools = inject(DevToolsService);

    //#endregion
    //#region Construction

    constructor() {
        this.hostApi.messageReceived.subscribe(message => {
            if (message.type === 'pip') {
                if (this.currentPlayer != null)
                    this.currentPlayer.setPipWindowMode(Boolean((message as any).value));
            }
        });

        this.setupDevTools();
        this.setupSessionHandlers();
    }

    //#endregion
    //#region Initialization

    private setupDevTools() {
        this.devTools.rootMenu.items.push(
            {
                id: 'media',
                label: 'Media',
                type: 'menu',
                icon: 'play_arrow',
                items: [
                    {
                        id: 'toggle_device_pip',
                        type: 'action',
                        label: 'Enable PiP Window Mode',
                        icon: 'picture_in_picture_alt',
                        handler: () => this.currentPlayer.setPipWindowMode(true)
                    },
                    {
                        id: 'toggle_device_pip',
                        type: 'action',
                        label: 'Disable PiP Window Mode',
                        icon: 'picture_in_picture_alt',
                        handler: () => this.currentPlayer.setPipWindowMode(false)
                    },
                    {
                        id: 'remote_audio_tester',
                        type: 'action',
                        label: 'Remote Audio Tester',
                        icon: 'picture_in_picture_alt',
                        handler: (_, i) => i.get(Router).navigateByUrl('/engineering/remote-audio-player-testing'),
                    }
                ]
            }
        );
    }

    private setActionHandlerSafely(action: MediaSessionAction, handler: MediaSessionActionHandler | null) {
        // Explanation: Some browsers (like Edge 122.0) do not support the full list of action handlers, and will throw
        // an exception if you try to attach one they don't support. We'd rather ensure all the action handlers that it
        // does support are connected, so this catches those exceptions and logs them out so we can continue.

        try {
            navigator.mediaSession.setActionHandler(action, handler);
        } catch (e) {
            console.error(`Failed to set action handler '${action}' on navigator.mediaSession:`);
            console.error(e);
        }

    }

    private setupSessionHandlers() {
        if (!isClientSide())
            return;

        if ("mediaSession" in navigator) {
            this.setActionHandlerSafely("play", () => this.session?.resume());
            this.setActionHandlerSafely("pause", () => this.session?.pause());
            this.setActionHandlerSafely("stop", () => this.session?.pause());
            this.setActionHandlerSafely("seekbackward", () => this.session?.seek(this.session.position - 10));
            this.setActionHandlerSafely("seekforward", () => this.session?.seek(this.session.position + 10));
            this.setActionHandlerSafely("seekto", ev => this.session?.seek(ev.seekTime + 10));
            this.setActionHandlerSafely("nexttrack", () => this.currentPlayer?.nextItem());
            this.setActionHandlerSafely("previoustrack", () => this.currentPlayer?.previousItem());
        }

        this.hostApi.messageOfType("media_session_resume").subscribe(() => this.session?.resume());
        this.hostApi.messageOfType("media_session_pause").subscribe(() => this.session?.pause());
        this.hostApi.messageOfType("media_session_stop").subscribe(() => this.session?.pause());
        this.hostApi.messageOfType("media_session_rewind").subscribe(() => this.session?.seek(this.session.position - 10));
        this.hostApi.messageOfType("media_session_fast_forward").subscribe(() => this.session?.seek(this.session.position + 10));
        this.hostApi.messageOfType("media_session_next_item").subscribe(() => this.currentPlayer?.nextItem());
        this.hostApi.messageOfType("media_session_previous_item").subscribe(() => this.currentPlayer?.previousItem());
        this.hostApi.messageOfType<MediaSessionSeekToMessage>("media_session_seek_to").subscribe(m => this.session?.seek(m.position / 1000));
    }

    //#endregion
    //#region Private Properties

    private sessionSubs = new Subscription();
    private _playerChanged: BehaviorSubject<Player> = new BehaviorSubject<Player>(null);
    private _currentPlayer: Player = null;
    private _session: PlaybackSession;
    private _sessionChanged: BehaviorSubject<PlaybackSession> = new BehaviorSubject<PlaybackSession>(null);

    readonly playerChanged = this._playerChanged.asObservable();

    //#endregion
    //#region Settings

    /**
     * Set to true if you need all player controls to remain visible.
     * This is used by remote play solutions like Google Cast and Airplay.
     */
    lockControlsVisible: boolean = false;

    /**
     * Set this to true to induce a constant buffering state. Useful for
     * orchestrated transitions like those found in Chromecast.
     */
    globalBuffering: boolean = false;

    /**
     * Whether or not playback session tracking (updates) are enabled.
     * This does not disable playback session start, only sending
     * updates to the server. This setting should be set to false
     * if in a remote playback scenario where the remote is handling
     * updating the play session (such as Chromecast).
     */
    enableSessionTracking: boolean = true;

    /**
     * The app-wide Player instance. This is provided by a PlayerComponent instantiated within the AppComponent.
     * It is used to allow playback to follow the user as they navigate through the app, even if playback started within
     * a specific route component (such as WatchComponent or WatchLiveComponent).
     *
     * The app player itself is responsible for setting this property, since doing otherwise would require a cyclical
     * dependency.
     */
    appPlayer: Player = null;

    //#endregion
    //#region State

    /**
     * The current Player instance. This will either be the app-wide player (`appPlayer`), or a PlayerComponent
     * running within the currently loaded route component (such as WatchComponent/WatchLiveComponent).
     */
    public get currentPlayer() {
        return this._currentPlayer;
    }

    /**
     * Be notified when the current PlaybackSession changes. Note that this is the actual PlaybackSession which
     * implements media playback, not the ApiPlaybackSession which is used to persist the user's progress watching
     * media to the backend.
     */
    readonly sessionChanged = this._sessionChanged.asObservable();

    /**
     * The current playback session.
     */
    get session() {
        return this._session;
    }

    /**
     * True when the app-wide player (`appPlayer`, hosted in AppComponent) is the current player (as opposed to a
     * player component running on a Watch or Watch Live page component). This is used by Google Cast to determine whether
     * it's appropriate to automatically navigate the app to the next Watch page when playback of an item finishes. It
     * can of course be used for other purposes.
     */
    get isAppPlayerActive() {
        return this._currentPlayer === this.appPlayer;
    }

    //#endregion
    //#region API

    private locksResolved = Promise.resolve();

    async lock(action: () => Promise<void>) {
        this.locksResolved = this.locksResolved.then(() => action()).catch(() => {});
        await this.locksResolved;
    }

    /**
     * Inform any other active player that the given player will be taking over playback responsibilities. There can
     * be only one active player in the app, so calling this will cause the other player to stop playing in deferrence
     * to the new active player. This is most important when media is playing in the app-wide player and the user
     * navigates to a new Watch or WatchLive route component which contains its own player. The expected behavior at
     * that point is for the app-wide player to stop playing and hide itself.
     *
     * @param player The player which is taking over
     * @returns
     */
    takePlayback(player: Player) {
        if (this._currentPlayer === player)
            return true;

        if (this._currentPlayer)
            this._currentPlayer.revokePlayback();

        this._currentPlayer = player;
        this._playerChanged.next(this._currentPlayer);

        return true;
    }

    /**
     * Called by the current player when it is being destroyed, but it does not consider session transfer (transferPlaybackToApp)
     * to be appropriate (for instance because the media is not currently being played, or the transfer is suppressed for other
     * reasons). Results in no player being current.
     *
     * @param player
     * @returns
     */
    releasePlayback(player: Player) {
        if (this._currentPlayer !== player)
            return;

        this._currentPlayer = null;
        this._playerChanged.next(null);
    }

    /**
     * Revokes playback privilege from whatever current player there happens to be and clears the current player.
     * This is used in situations where another player (or some other part of the app) would like playback to stop,
     * but does not intend to takePlayback() itself. As an example, this is used when PlayerComponent shows a paywall,
     * since it does not start a new playback session in that case and otherwise the app-wide player would continue to
     * play.
     */
    revokePlayback() {
        if (this._currentPlayer)
            this._currentPlayer.revokePlayback();
        this._currentPlayer = null;
    }

    /**
     * Called by a Player when the session is being disposed.
     * @param session
     * @returns
     */
    unregisterSession(session: PlaybackSession) {
        if (this._session !== session) {
            console.warn(`[Playback] Received request to unregister session which is not the current serssion.`);
            return;
        }

        this.changeActiveSession(null, null);
    }

    /**
     * Called by a Player when it has a new session that is becoming active.
     * @param session
     * @param mediaMetadata
     */
    registerSession(session: PlaybackSession, mediaMetadata: PlaybackSessionMetadata) {
        this.changeActiveSession(session, mediaMetadata);
    }

    /**
     * Called by a Player which is not the app player when it is being destroyed while playback is ongoing.
     * Should result in the playback session being transferred into the app-wide player (see `appPlayer`).
     * The existing playback session will survive this change if the underlying plugin supports seamless moving.
     * Otherwise the session will be terminated and replaced by a duplicate within the new player.
     *
     * @param session
     * @param production
     * @param playlistID
     * @param item
     * @param position
     * @param pictureInPicture
     */
    transferPlaybackToApp(
        session: PlaybackSession,
        production: ApiProduction,
        playlistID: PlaylistType,
        item: ApiVOD | ApiPodcast | ApiLiveStream,
        position: number,
        pictureInPicture?: boolean
    ) {
        if (session?.moveInto) {
            // This session (for instance HTML5 audio/video) supports seamlessly moving its playback element from one
            // player to another without interrupting playback. Some playback plugins like YouTube (based on <iframe>)
            // cannot support this maneuver, at least until we have the ability to move iframes without losing state
            // (the Portals proposal may make this possible, or https://github.com/whatwg/html/issues/8538)

            this.appPlayer?.receiveSession(
                session,
                production,
                playlistID,
                item,
                pictureInPicture,
            );
        } else {
            // This session does not support seamless moves, so we'll start playing the passed content on the app player
            // directly. The app player will use takePlayback() and registerSession() to cause the current player to stop
            // playing if it hasn't already.

            this.appPlayer?.playContent(
                production,
                playlistID,
                item,
                position,
                pictureInPicture
            );
        }
    }

    //#endregion
    //#region Business Logic

    /**
     * Send the current playback state and pip allowance information to TYT Native, if we are running in it.
     * @param state
     */
    private sendNativePlaybackState(state: PlaybackState | 'finished') {
        this.hostApi.sendMessage({ type: 'playback_state_changed', state });
        this.hostApi.sendMessage({ type: 'allow_pip', value: ['playing', 'buffering'].includes(state) });
    }

    /**
     * Responsible for attaching/detaching important event handlers on the current playback session, such as ensuring
     * that the HTML5 MediaSession API is fed the appropriate information, as well as feeding the same information up
     * to a TYT Native shell so that it can use the platform-specific APIs when needed. Also managed picture-in-picture
     * eligibility for TYT Native (ie, should the Android app be allowed to automatically enter picture-in-picture when
     * the user leaves the app? That would depend on the current playback state).
     *
     * @param session
     * @param mediaMetadata
     */
    private changeActiveSession(session: PlaybackSession, mediaMetadata: PlaybackSessionMetadata) {
        this._session = session;
        this._sessionChanged.next(this._session);

        this.sessionSubs?.unsubscribe();
        this.sessionSubs = undefined;

        this.updateSessionMetadata(mediaMetadata);

        // If we have a session, attach event handlers so we can keep the MediaSession API and TYT Native up to date
        // with what's currently going on.

        if (session) {
            this.sendNativePlaybackState(session.playState ?? 'unstarted');
            this.sessionSubs = new Subscription();
            this.sessionSubs.add(session.playStateChanged.subscribe(state => this.sendNativePlaybackState(state)));
            this.sessionSubs.add(session.finished.subscribe(() => this.sendNativePlaybackState('finished')));
            this.sessionSubs.add(session.positionChanged.subscribe(() => this.updateSessionPositionState(session)));

            if (session.speedChanged)
                this.sessionSubs.add(session.speedChanged.subscribe(() => this.updateSessionPositionState(session)));
            this.sessionSubs.add(session.lengthChanged.subscribe(() => this.updateSessionPositionState(session)));
        } else {
            this.sendNativePlaybackState('unstarted');
        }
    }

    /**
     * Update the current playback position information on the MediaSession (if supported), and in the native shell if
     * running in TYT Native.
     *
     * @param session The playback session to draw from
     */
    private updateSessionPositionState(session: PlaybackSession) {
        if ("mediaSession" in navigator) {
            navigator.mediaSession.setPositionState({
                duration: session.length || 0,
                playbackRate: session.speed || 1,
                position: Math.min(session.position || 0, session.length || 0) // position cannot be greater than duration
            });
        }

        this.hostApi.sendMessage({
            type: "playback_position_changed",
            noisy: true,
            playback_state: {
                duration: session.length * 1000 | 0,
                playback_rate: session.speed,
                position: session.position * 1000 | 0
            }
        });
    }

    /**
     * Update the media metadata associated with the MediaSession (if supported), and in the native shell if running
     * in TYT Native.
     *
     * @param mediaMetadata The media metadata to send. Can be `null` to clear the current metadata.
     */
    private updateSessionMetadata(mediaMetadata: PlaybackSessionMetadata) {
        if ("mediaSession" in navigator) {
            navigator.mediaSession.metadata = mediaMetadata ? new MediaMetadata({
                title: mediaMetadata.title,
                artist: mediaMetadata.artist,
                album: mediaMetadata.album,
                artwork: mediaMetadata.artwork
            }) : undefined;
        }

        this.hostApi.sendMessage({
            type: 'media_metadata',
            metadata: mediaMetadata ? {
                title: mediaMetadata.title ?? `Untitled`,
                artist: mediaMetadata.artist ?? `TYT`,
                album: mediaMetadata.album ?? ``,
                artwork: mediaMetadata.artwork?.[0]?.src ?? null,
                mediumType: mediaMetadata.mediumType
            } : null
        });
    }
    //#endregion
}
