import {Component, inject, Input, OnInit} from '@angular/core';
import { ApiHomeRow, ApiLiveSchedule, ApiLiveScheduleList, ApiLiveStream } from '@tytapp/api';
import { HomeCardComponentType } from '@tytapp/app/pages/home-page/home-cards.service';
import { BillingService } from '@tytapp/billing';
import { Icon, Link, PageComponent } from '@tytapp/common';
import { isServerSide } from '@tytapp/environment-utils';
import { LiveStreamsService } from '@tytapp/live-streams';

@Component({
    selector: 'tyt-todays-live-schedule',
    templateUrl: './todays-live-schedule.component.html',
    styleUrls: ['./todays-live-schedule.component.scss']
})
export class TodaysLiveScheduleComponent extends PageComponent implements HomeCardComponentType, OnInit {

    // Home Card

    card: ApiHomeRow;
    title = 'Live Schedule';
    icon: Icon = { type: 'material', text: 'podcasts' };
    more: Link = { url: '/live/schedule', label: 'Full Schedule', icon: { type: 'material', text: 'open_in_browser' } };

    // Services

    private liveStreamsService = inject(LiveStreamsService);
    private billing = inject(BillingService);

    // State

    state: 'loading' | 'ready' = 'loading';
    useTimezone: string = 'local';
    streams: ApiLiveStream[];
    timezoneString = null;
    timezone: string = null;
    entitled = false;
    schedule: ApiLiveScheduleList[];
    upcomingEvents: ApiLiveSchedule[];

    private _numberOfDays: number = 2; // Default to 2 day

    @Input() get numberOfDays() { return this._numberOfDays; }
    set numberOfDays(value) {
        this._numberOfDays = value;
        setTimeout(() => this.determineUpcomingEvents());
    }

    @Input() maximumEvents = 8;

    // Logic

    async init() {
        this.subscribe(this.billing.entitlementChanged, entitled => this.entitled = entitled);
        this.fetchLiveSchedule();
        this.streams = await this.liveStreamsService.active();
        this.state = 'ready';
    }

    async fetchLiveSchedule() {
        let options: Intl.DateTimeFormatOptions = { timeZoneName: 'short' };
        let customOffset = undefined;

        if (isServerSide()) {
            options['timeZone'] = 'America/New_York';
            customOffset = this.getTimezoneOffset(new Date(), options['timeZone']);
        }

        this.schedule = await this.liveStreamsService.getSchedule(customOffset);
        if (!this.schedule) {
            throw new Error(`Failed to load schedule from getSchedule() API!`);
        }

        this.determineUpcomingEvents();

        let date = new Date();
        this.timezoneString = date.toLocaleTimeString('en-us', options).split(' ')[2];
        if (isServerSide() || this.useTimezone != 'local')
            this.timezone = this.timezoneString;
        else
            this.timezone = null;

        let simpleTzMap = {
            PST: 'PT',
            PDT: 'PT',
            CST: 'CT',
            CDT: 'CT',
            MST: 'MT',
            MDT: 'MT',
            EST: 'ET',
            EDT: 'ET'
        };

        if (simpleTzMap[this.timezoneString]) {
            this.timezoneString = simpleTzMap[this.timezoneString];
        }
    }

    determineUpcomingEvents() {
        if (!this.schedule || this.schedule.length === 0) return;

        let daysOfTheWeek = {
            'sunday': 0,
            'monday': 1,
            'tuesday': 2,
            'wednesday': 3,
            'thursday': 4,
            'friday': 5,
            'saturday': 6
        };

        let schedulesByDay: ApiLiveScheduleList[] = [];
        for (let schedule of this.schedule) {
            let index = daysOfTheWeek[schedule.day];
            schedulesByDay[index] = schedule;
        }

        let currentDay = new Date().getDay();

        let upcoming = [];
        let daysFilled = 0;

        for (let i = 0; i < 7 && daysFilled < this.numberOfDays; ++i) {
            let index = (currentDay + i) % 7;
            let schedule = schedulesByDay[index];
            if (schedule && schedule.live_schedules.length > 0) {
                upcoming = upcoming.concat(schedule.live_schedules);
                daysFilled += 1;
            }
        }

        this.upcomingEvents = upcoming;
    }

    todayCount = 0;

    limitArray(array: any[], length) {
        if (!array)
            return [];

        return array.slice(0, length);
    }

    getTimezoneOffset(d, tz) {
        var a = d.toLocaleString("ja", { timeZone: tz }).split(/[\/\s:]/);
        a[1]--;
        var t1 = Date.UTC.apply(null, a);
        var t2 = new Date(d).setMilliseconds(0);
        return (t2 - t1) / 60 / 1000 / 60;
    }

    getEventStream(event: ApiLiveSchedule): ApiLiveStream {
        if (!this.streams)
            return null;

        let streams = this.streams.filter(x => x.active_live_event && x.active_live_event.id == event.id);
        let premiumStream = streams.filter(x => x.premium)[0];
        let publicStream = streams.filter(x => !x.premium)[0];

        if (this.entitled)
            return premiumStream || publicStream;

        return publicStream || premiumStream;
    }

    liveStreamRoute(event: ApiLiveSchedule) {
        const eventStream = this.getEventStream(event);
        return eventStream ? `/live/streams/${ eventStream.id }` : undefined;
    }

    airTimeForItem(item: ApiLiveSchedule) {
        return item.air_time_unix ? Number(item.air_time_unix) * 1000 : undefined;
    }
}
