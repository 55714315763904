/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class ShowsApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Get a feed of the show&#39;s most recent articles
     * @param id 
     * @param offset 
     * @param limit 
     */
    public getShowArticles(id?: string, offset?: number, limit?: number, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedArticle>> {
        const path = '/content/shows/{id}/feed/articles'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedArticle>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a feed of the show&#39;s most recent clips
     * @param id 
     * @param offset 
     * @param limit 
     * @param availability 
     */
    public getShowClips(id?: string, offset?: number, limit?: number, availability?: string, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/shows/{id}/feed/clips'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }

        if (availability !== undefined) {
            queryParameters['availability'] = <any>availability;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a feed of the show&#39;s most recent productions
     * @param id 
     * @param offset 
     * @param limit 
     * @param availability 
     */
    public getShowProductions(id?: string, offset?: number, limit?: number, availability?: string, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/shows/{id}/feed/all'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }

        if (availability !== undefined) {
            queryParameters['availability'] = <any>availability;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a feed of the show&#39;s most recent podcasts
     * @param id 
     * @param offset 
     * @param limit 
     * @param availability 
     */
    public getShowPodcasts(id?: string, offset?: number, limit?: number, availability?: string, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/shows/{id}/feed/podcasts'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }

        if (availability !== undefined) {
            queryParameters['availability'] = <any>availability;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a feed of the show&#39;s most recent episodes
     * @param id 
     * @param offset 
     * @param limit 
     */
    public getShowEpisodes(id?: string, offset?: number, limit?: number, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/shows/{id}/feed/episodes'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a specific show
     * @param id 
     */
    public get(id?: string, __unused?: any): Observable<models.ApiShow> {
        const path = '/content/shows/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiShow>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of past shows
     */
    public past(__unused?: any): Observable<PagedArray<models.ApiShow>> {
        const path = '/content/shows/past';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiShow>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of shows
     */
    public all(__unused?: any): Observable<PagedArray<models.ApiShow>> {
        const path = '/content/shows';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiShow>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
