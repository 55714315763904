var $ = require('../internals/export');
var ownKeys = require('../internals/own-keys');

// `Reflect.ownKeys` method
// https://tc39.es/ecma262/#sec-reflect.ownkeys
$({
  target: 'Reflect',
  stat: true
}, {
  ownKeys: ownKeys
});