"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Doctype = exports.CDATA = exports.Tag = exports.Style = exports.Script = exports.Comment = exports.Directive = exports.Text = exports.Root = exports.isTag = exports.ElementType = void 0;
/** Types of elements found in htmlparser2's DOM */
var ElementType;
(function (ElementType) {
  /** Type for the root element of a document */
  ElementType["Root"] = "root";
  /** Type for Text */
  ElementType["Text"] = "text";
  /** Type for <? ... ?> */
  ElementType["Directive"] = "directive";
  /** Type for <!-- ... --> */
  ElementType["Comment"] = "comment";
  /** Type for <script> tags */
  ElementType["Script"] = "script";
  /** Type for <style> tags */
  ElementType["Style"] = "style";
  /** Type for Any tag */
  ElementType["Tag"] = "tag";
  /** Type for <![CDATA[ ... ]]> */
  ElementType["CDATA"] = "cdata";
  /** Type for <!doctype ...> */
  ElementType["Doctype"] = "doctype";
})(ElementType = exports.ElementType || (exports.ElementType = {}));
/**
 * Tests whether an element is a tag or not.
 *
 * @param elem Element to test
 */
function isTag(elem) {
  return elem.type === ElementType.Tag || elem.type === ElementType.Script || elem.type === ElementType.Style;
}
exports.isTag = isTag;
// Exports for backwards compatibility
/** Type for the root element of a document */
exports.Root = ElementType.Root;
/** Type for Text */
exports.Text = ElementType.Text;
/** Type for <? ... ?> */
exports.Directive = ElementType.Directive;
/** Type for <!-- ... --> */
exports.Comment = ElementType.Comment;
/** Type for <script> tags */
exports.Script = ElementType.Script;
/** Type for <style> tags */
exports.Style = ElementType.Style;
/** Type for Any tag */
exports.Tag = ElementType.Tag;
/** Type for <![CDATA[ ... ]]> */
exports.CDATA = ElementType.CDATA;
/** Type for <!doctype ...> */
exports.Doctype = ElementType.Doctype;