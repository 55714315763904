
export function zeroPad(number: number | string, count: number = 2) {
    let str: string;

    if (typeof number === 'number')
        str = String(number);
    else
        str = number;

    while (str.length < count)
        str = '0' + str;

    return str;
}

export function leftPad(str: string, count: number = 2) {
    while (str.length < count)
        str = ' ' + str;

    return str;
}

export function rightPad(str: string, count: number = 2) {
    while (str.length < count)
        str = str + ' ';

    return str;
}

/**
 * Remove leading white space from the given string. This is detected using the indentation of the last line. To use
 * this effectively, use the following code formatting:
 *
 * ```
 * unindent(
 *      `
 *      Content goes here
 *      <-- This amount of indentation will be removed.
 *      |   <-- Indentation beyond will be preserved
 *      V-- Last line must be indented for this to work.
 *      `
 * )
 * ```
 *
 * @param str
 * @returns
 */
export function unindent(str : string) {
    let lastNewline = str.lastIndexOf("\n");
    let indent = str.slice(lastNewline+1).replace(/[^ ]/g, '');
    return str.split(/\n/g).map(x => x.replace(indent, '')).join("\n");
}