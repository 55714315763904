import { Component, HostBinding, inject } from '@angular/core';
import { ContentBlockViewComponent } from './content-block-view-component';
import { UserService } from '@tytapp/user';
import { environment } from '@tytapp/environment';

@Component({
    selector: 'tyt-missing-content-block-view',
    template: `
        @if (user?.staff && !hidden) {
            <div class="alert alert-warning with-icon long">

                <mat-icon>warning</mat-icon>
                <div>
                    <strong>Missing block type <code>{{block.type}}</code></strong>
                    <p>This page calls for a block that is not supported. This usually happens when this version of {{ productName }} is too old to display
                        a certain block type. </p>
                    <span class="tag" matTooltip="This message is only shown to staff users. Nothing will be shown to end users.">
                        <mat-icon>lock</mat-icon>
                        Staff only
                    </span>

                    &nbsp;

                    <button mat-button (click)="hide()">
                        <mat-icon>close</mat-icon>
                        Hide
                    </button>
                </div>
            </div>
        }
    `
})
export class MissingContentBlockViewComponent extends ContentBlockViewComponent {
    private userService = inject(UserService);

    hidden = false;

    get productName() {
        return environment.productName;
    }

    @HostBinding('attr.data-block-id')
    get id() {
        return this.block.type;
    }

    get user() {
        return this.userService.user;
    }

    hide() {
        this.hidden = true;
    }
}