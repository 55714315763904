var $ = require('../internals/export');
var global = require('../internals/global');

// `globalThis` object
// https://tc39.es/ecma262/#sec-globalthis
$({
  global: true,
  forced: global.globalThis !== global
}, {
  globalThis: global
});