import { CommentsOrder } from '@banta/common';
import { ContentBlockType } from '@tytapp/content';
import { CommentsBlock } from './comments-block';

export * from './comments-block';

export const COMMENTS_BLOCK_TYPE: ContentBlockType<CommentsBlock> = {
    id: 'comments',
    label: 'Comments',
    icon: 'forum',
    preview: {
        isPreview: true,
        defaultSortOrder: CommentsOrder.NEWEST
    },
    template: {
        requirementType: 'membership',
        defaultSortOrder: CommentsOrder.LIKES,
        showHeader: true
    },
    deferredEditorComponent: () => import('./comments-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./comments-block.view').then(m => m.ViewComponent)
};
