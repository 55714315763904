import { inject, Injectable } from '@angular/core';
import { ApiUser, UsersApi } from '@tytapp/api';
import { AppConfig, DevToolsService, formatDateUS, HostApi, LoggerService, Message, Redirection, Shell } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { NotificationItem, NotificationsService } from '@tytapp/notifications';
import { UserService } from '@tytapp/user';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

export interface BillingAlertParams {
    id?: string;
    label?: string;
    entitled: boolean;
    paymentStatus: string;
    fullyCancelled: boolean;
    renewsOn: Date;
    period: string;
    cancelledAt: Date;
    gateway: string;
    hasMembership: boolean;
    isStaff: boolean;
    signedIn: boolean;
}

const BILLING_SCENARIOS: BillingAlertParams[] = [
    {
        id: 'visitor',
        label: 'Visitor',
        signedIn: false, entitled: false, fullyCancelled: false, gateway: null,
        hasMembership: false, isStaff: false, paymentStatus: null, period: null,
        renewsOn: null, cancelledAt: null
    },
    {
        id: 'cancelling',
        label: 'Cancelling',
        signedIn: true,
        entitled: true,
        fullyCancelled: false,
        cancelledAt: new Date(Date.now() - 1000 * 60 * 15),
        gateway: 'stripe',
        hasMembership: true,
        isStaff: false,
        paymentStatus: 'cancelling',
        period: 'monthly',
        renewsOn: new Date(Date.now() + 1000 * 60 * 60 * 24 * 5)
    },
    {
        id: 'pastDue',
        label: 'Past Due',
        signedIn: true,
        entitled: true,
        fullyCancelled: false,
        cancelledAt: null,
        gateway: 'stripe',
        hasMembership: true,
        isStaff: false,
        paymentStatus: 'past_due',
        period: 'monthly',
        renewsOn: new Date(Date.now() - 1000 * 60 * 60 * 24 * 5)
    },
    {
        id: 'revoked',
        label: 'Revoked',
        signedIn: true,
        entitled: false,
        fullyCancelled: true,
        cancelledAt: null,
        gateway: 'stripe',
        hasMembership: true,
        isStaff: false,
        paymentStatus: 'expired',
        period: 'monthly',
        renewsOn: new Date(Date.now() - 1000 * 60 * 60 * 24 * 38)
    }
];

export interface BillingTokenChangedMessage extends Message {
    type: 'billing_token_changed';
    token: string;

    /**
     * Needed for Apple IAP. Indicate what operation led to this billing change.
     * If not present, assumes 'purchase'
     */
    operation?: 'refresh' | 'login' | 'restore_purchase' | 'purchase';
}

@Injectable()
export class BillingService {
    private shell = inject(Shell);
    private notifications = inject(NotificationsService);
    private userService = inject(UserService);
    private devTools = inject(DevToolsService);
    private hostApi = inject(HostApi);
    private userApi = inject(UsersApi);
    private appConfig = inject(AppConfig);
    private redirection = inject(Redirection);
    private logger = inject(LoggerService);

    init() {
        this.installDevTools();
        this.userService.userChanged.subscribe(user => this.applyBillingAlerts(user));

        this.hostApi.messageReceived.subscribe(async msg => {
            if (msg.type === 'billing_token_changed') {
                let billingMessage = <BillingTokenChangedMessage>msg;
                this.logger.info(`[BillingService] Platform billing token has changed: ${billingMessage.token}`);
                await this.handleBillingTokenChange(billingMessage.token, billingMessage.operation);
            }
        });

        this.hostApi.capabilitiesReady.then(async () => {
            await this.appConfig.appStatusReady;

            if (await this.hostApi.hasCapability('platform_billing:membership')) {
                if (this.appConfig.appStatus?.settings?.product_id?.[0]) {
                    this.hostApi.sendMessage({
                        type: 'check_for_billing_tokens',
                        product_id: this.appConfig.appStatus.settings.product_id[0]
                    });
                }
            }

            if (await this.hostApi.hasCapability('platform_billing:app_store')) {
                let billingMode = localStorage.getItem('tyt:platform_billing_mode') ?? 'production';

                this.devTools.appendInto('billing', [
                    {
                        id: 'billing:toggle_sandbox',
                        icon: 'credit_card',
                        type: 'action',
                        label: `IAP Mode: ${billingMode}`,
                        handler: () => {
                            billingMode = billingMode === 'sandbox' ? 'production' : 'sandbox';
                            this.hostApi.sendMessage({
                                type: 'change_billing_mode',
                                mode: billingMode
                            });

                            this.devTools.getActionById('billing:toggle_sandbox').label = `IAP Mode: ${billingMode}`;
                            localStorage.setItem('tyt:platform_billing_mode', billingMode);
                            alert(`Billing mode has been set to '${billingMode}'`);
                        },
                    }
                ]);

                this.hostApi.sendMessage({
                    type: 'change_billing_mode',
                    mode: billingMode
                });
            }
        });

        let wasEntitled = false;
        let hasFired = false;
        this.userService.identityChanged.subscribe(user => {
            if (!hasFired || wasEntitled !== user?.entitled) {
                this._entitlementChanged.next(user?.entitled);
                hasFired = true;
                wasEntitled = user?.entitled;
            }
        });
    }

    private _entitlementChanged = new ReplaySubject<boolean>(1);
    private _entitlementChanged$ = this._entitlementChanged.asObservable();
    public get entitlementChanged() { return this._entitlementChanged$; }

    private _token: string;

    get token() {
        return this._token;
    }

    public async isEntitled() {
        return this.entitlementChanged.pipe(take(1)).toPromise();
    }

    private async handleBillingTokenChange(token: string, operation?: string) {
        await this.userService.ready;
        await this.appConfig.appStatusReady;

        this._token = token;

        // Important to understand here how platform-specific billing (Google Play / App Store) works
        // in our system. We waited to handle this method until the user state is ready (ie being logged in,
        // or being a guest). This is to ensure that we send the authenticate() API call with the user's current
        // JWT. We then will use the play_token/receipt authentication methods and pass the new token/receipt.
        //
        // The server has the option of associating the purchase with the user identified by the passed JWT,
        // or finding the user already associated with the subscription and returning that. If the user is a
        // Guest (as opposed to a registered user), it may opt to merge data from the guest account into the
        // known User account which owns the subscription. Thus, our user identity may or may not change as a
        // result of sending the new token/receipt to the server, so we need to call userService.completeLogin
        // to address that.

        if (token) {
            if (await this.hostApi.hasCapability('platform_billing:google_play')) {
                this.logger.info(`[BillingService] Conveying Google Play token to backend...`);

                try {
                    let result = await this.userApi.authenticate({
                        method: 'play_token',
                        product_id: this.appConfig.appStatus.settings.product_id?.[0] ?? 'monthly_4.99',
                        play_token: token,
                    }).toPromise();

                    await this.userService.completeLogin(result, result.token, false, () => {});
                } catch (e) {
                    if (e.json)
                        e = e.json();

                    if (environment.showDevTools) {
                        alert(`Error occurred while signing in via Play subscription: ${e.message ?? e.error}`);
                    } else {
                        alert(
                            `Uh oh, we couldn't validate your in-app purchase history. `
                            + `If this issue persists, please contact support at support@tytnetwork.com`
                        );
                    }
                }
            } else if (await this.hostApi.hasCapability('platform_billing:app_store')) {
                this.logger.info(`[BillingService] Conveying In App Purchase receipt to backend...`);

                try {
                    let result = await this.userApi.authenticate({
                        method: 'receipt',
                        iap_receipt: token,
                        iap_type: operation ?? 'purchase'
                    }).toPromise();

                    await this.userService.completeLogin(result, result.token, false, () => {});
                } catch (e) {

                    if ((e.error as string)?.includes('Sandbox receipt in Production environment')) {
                        this.logger.warning(`Failed to sign in via IAP: Sandbox receipt present but currently targetting production environment.`);
                        return;
                    }

                    if (e.error === 'Bad receipt') {
                        this.logger.warning(`Failed to sign in via IAP: Bad receipt.`);
                        return;
                    }

                    if (environment.showDevTools) {
                        alert(`Error occurred while signing in via IAP receipt: ${e.message ?? e.error}`);
                    } else {
                        alert(
                            `Uh oh, we couldn't validate your in-app purchase history. `
                            + `If this issue persists, please contact support at support@tytnetwork.com`
                        );
                    }

                }
            } else {
                this.logger.error(`Error: Received billing_token_changed, but no known platform-specific capability is present. Ignoring!`);
            }
        }
    }

    private installDevTools() {
        this.devTools.rootMenu.items.push(
            {
                type: 'menu',
                id: 'billing',
                label: 'Billing',
                icon: 'credit_card',
                items: [
                    {
                        id: 'triggerNativeBilling',
                        type: 'action',
                        label: 'Native billing',
                        icon: 'credit_card',
                        handler: (action, injector) => {
                            injector.get(BillingService).presentNativeBillingExperience();
                        },
                    },
                    {
                        id: 'triggerNativeBilling',
                        type: 'action',
                        label: 'Native billing: Active/Entitled',
                        icon: 'credit_card',
                        handler: (action, injector) => {
                            injector.get(BillingService).presentNativeBillingExperience('active-entitled');
                        },
                    },
                    {
                        id: 'triggerNativeBilling',
                        type: 'action',
                        label: 'Native billing: Active/Unentitled',
                        icon: 'credit_card',
                        handler: (action, injector) => {
                            injector.get(BillingService).presentNativeBillingExperience('active-unentitled');
                        },
                    },
                    {
                        id: 'triggerNativeBilling',
                        type: 'action',
                        label: 'Native billing: Unentitled',
                        icon: 'credit_card',
                        handler: (action, injector) => {
                            injector.get(BillingService).presentNativeBillingExperience('unentitled');
                        },
                    },
                    {
                        id: 'billingScenarios',
                        type: 'menu',
                        icon: 'credit_card',
                        label: 'States',
                        items: BILLING_SCENARIOS.map(scenario => ({
                            type: 'action',
                            id: `billingScenario:${scenario.id}`,
                            label: scenario.label,
                            handler: (item, injector) => injector.get(BillingService)
                                .applyBillingAlertsWithParams(scenario)
                        }))
                    }
                ]
            },
        )
    }

    private async applyBillingAlerts(user: ApiUser) {
        this.shell.removeAlert('billing');
        this.removeAllLocalBillingNotifications();

        if (!user || !user.membership)
            return;

        this.applyBillingAlertsWithParams({
            cancelledAt: user.membership.cancelled_at ? new Date(user.membership.cancelled_at) : null,
            entitled: user.entitled,
            hasMembership: true,
            fullyCancelled: user.membership.status ? user.membership.status.canceled : false,
            gateway: user.membership.gateway_name,
            isStaff: user.staff,
            paymentStatus: user.membership.payment_status,
            renewsOn: user.membership.renews_on ? new Date(user.membership.renews_on) : null,
            period: user.membership.product ? user.membership.product.period : user.membership.period,
            signedIn: true
        });
    }

    private addLocalBillingNotification(notif: Omit<NotificationItem, 'type' | 'tags' | 'timestamp'>) {
        this.notifications.add({
            type: 'local',
            tags: ['localBilling'],
            timestamp: new Date(),
            ...notif
        });
    }

    private removeAllLocalBillingNotifications() {
        this.notifications.removeAllTagged('localBilling');
    }

    async applyBillingAlertsWithParams(params: BillingAlertParams) {
        this.shell.removeAlert('billing');
        this.removeAllLocalBillingNotifications();

        if (!params.signedIn || params.isStaff)
            return;

        if (params.hasMembership) {
            let gracePeriod = params.period === 'monthly' ? 1000 * 60 * 60 * 24 * 14 : 1000 * 60 * 60 * 24 * 30;
            let cutoffDate = params.renewsOn ? new Date(params.renewsOn.getTime() + gracePeriod) : null;
            let pastDueDays = params.renewsOn ? (Date.now() - params.renewsOn.getTime()) / 1000 / 60 / 60 / 24 : 0;

            let gateway = params.gateway;
            let skip_gateways = ['paypal_subscription', 'youtube'];
            if (!skip_gateways.includes(gateway)) {
                if (await this.shell.hasFeature('apps.web.enable_notifications')) {
                    if (params.cancelledAt) {
                        if (!params.fullyCancelled) {
                            this.addLocalBillingNotification({
                                id: 'reactivate',
                                category: 'warning',
                                style: 'attention',
                                text: 'Reactivate membership',
                                description: `Your membership is set to end on ${formatDateUS(params.renewsOn)}. You can still reactivate and keep your membership!`,
                                url: `/settings/memberships`,
                                icon: 'schedule'
                            });
                        }
                    } else if (params.paymentStatus == 'past_due' && pastDueDays >= 4) {
                        let renewalDate = params.renewsOn;
                        let isBeforeCutoff = renewalDate.getTime() < Date.now() && cutoffDate;
                        this.addLocalBillingNotification({
                            id: 'pastDue',
                            category: 'warning',
                            text: `Your membership renewal is past due`,
                            icon: 'warning',
                            style: 'attention',
                            description: isBeforeCutoff ?
                                `Update your billing information before ${formatDateUS(cutoffDate)} to avoid losing access`
                                : `Update your billing information to avoid losing access`,
                            url: `/settings/memberships`
                        });
                    } else if (!params.entitled) {
                        this.addLocalBillingNotification({
                            id: 'unentitled',
                            category: 'error',
                            icon: 'error',
                            text: `Renew TYT`,
                            style: 'attention',
                            description: `A problem with your TYT membership prevents you from accessing membership content. Click here to resolve.`,
                            url: `/settings/memberships`
                        });
                    }
                } else {
                    if (params.cancelledAt) {
                        this.shell.addAlert({
                            id: 'billing',
                            type: 'warning',
                            message: params.fullyCancelled
                                ? 'Renew TYT'
                                : 'Reactivate membership!',
                            url: `/settings/memberships`
                        });
                    } else if (params.paymentStatus == 'past_due') {
                        this.shell.addAlert({
                            id: 'billing',
                            type: 'warning',
                            message: `Please update your billing info`,
                            url: `${environment.urls.accounts}/updateBilling`
                        });
                    } else if (!params.entitled) {
                        this.shell.addAlert({
                            id: 'billing',
                            type: 'warning',
                            message: `Renew TYT`,
                            url: `/settings/memberships`
                        });
                    }
                }
            }
        }
    }

    async restorePurchases() {
        if (!await this.hostApi.hasCapability('platform_billing:restore_purchases')) {
            alert(`Restore Purchases is not supported on this platform.`);
            return;
        }

        this.hostApi.sendMessage({
            type: 'restore_purchases'
        });
    }

    private async presentNativeBillingExperience(state?: 'active-unentitled' | 'active-entitled' | 'unentitled') {

        if (!state) {
            if (this.userService.user?.entitled && this.userService.user?.membership?.active)
                state = 'active-entitled';
            else if (this.userService.user?.membership?.active)
                state = 'active-unentitled';
            else
                state = 'unentitled';
        }

        if (state === 'active-entitled') {
            await this.shell.alert(
                `Already a member`,
                `
                    You already have a TYT membership and should already be receiving benefits.
                    If you are experiencing a problem, please visit Settings -> Plus Memberships
                    to check the status of your membership or contact support@tytnetwork.com
                `
            );
        } else if (state === 'active-unentitled') {
            await this.shell.alert(
                `Already a member`,
                `
                    You are already a TYT member, but it looks like your membership requires attention.
                `
            );
            this.redirection.go(`/settings/memberships`);
        } else if (await this.hostApi.isCapabilityEmulated('platform_billing:membership')) {
            alert(`Would trigger the platform-specific billing experience`);
        } else {
            this.hostApi.sendMessage({
                type: 'start_billing',
                product_id: this.appConfig.appStatus.settings.product_id?.[0] ?? 'monthly_4.99',
                return_url: '/shows',
                message: 'Test Header',
                signed_in: this.userService.isLoggedIn,
                is_renewal: false, // TODO
                is_eligible_for_free_trial: false, // TODO
            })
        }
    }

    async presentMembershipOffers() {
        await this.appConfig.appStatusReady;
        await this.userService.ready;

        if (await this.hostApi.hasCapability('web_billing:membership')) {
            this.redirection.go(environment.urls.purchase, true);
        } else if (await this.hostApi.hasCapability('platform_billing:membership')) {
            await this.presentNativeBillingExperience();
        } else {
            this.logger.error(`[BillingService.presentMembershipOffers()] Cannot initiate membership join activity: No membership billing capability is present.`);
        }
    }
}