import { ContentBlockType } from '@tytapp/content';
import { PaidSupportC2aBlock } from './paid-support-c2a-block';

export * from './paid-support-c2a-block';

export const PAID_SUPPORT_C2A_BLOCK_TYPE: ContentBlockType<PaidSupportC2aBlock> = {
    id: 'paid-support-c2a',
    label: 'Engagement Ladder C2A',
    icon: 'monetization_on',
    editable: false,
    template: {
    },
    preview: {
    },
    deferredEditorComponent: () => import('./paid-support-c2a-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./paid-support-c2a-block.view').then(m => m.ViewComponent)
}
